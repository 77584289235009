import React, { useContext, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import AuthContext from "../../authContext";
import ResetPasswordForm from "./ResetPasswordForm";
import LoginCard from "./LoginCard";
import MFAForm from "./MFAForm";
import NewPasswordForm from "./NewPasswordForm";
import { isEmailValid } from "../../util";
import { loginButtonStyles } from "./util";
import { AppRoutes } from "../../routing";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";
import OktaLogoPNG from "../../icons/OktaLogoPNG.png";
import { MFA_APP_CHALLENGE_NAME } from "../../AuthProvider";

const authContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  p: { xs: 0 },
};

interface Props {
  onUserForgotPassword: () => void;
  onBackToLoginClick: () => void;
  onOktaClick: () => void;
}

interface InputData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  department: string;
  title: string;
  newPassword: string;
  confirmNewPassword: string;
}

const DEFAULT_INPUT_DATA = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  department: "",
  title: "",
  newPassword: "",
  confirmNewPassword: "",
};

const Login: React.FC<Props> = ({
  onUserForgotPassword,
  onBackToLoginClick,
  onOktaClick,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    completeNewPassword,
    isLoading,
    errorMessage,
    newPasswordRequired,
    mfaAppRequired,
    resetErrorMessage,
    signin,
    userForgotPassword,
    resetPasswordSubmit,
    sendResetPasswordEmail,
    showForgotPasswordConfirm,
    validateEmail,
    invalidEmail,
    handleMFAVerification,
    cognitoUser,
  } = useContext(AuthContext);

  const [inputData, setInputData] = useState<InputData>(DEFAULT_INPUT_DATA);
  const { email, password } = inputData;

  const resetPasswordMetadata = (() => {
    const pathNameArray = location.pathname.split("/");

    if (pathNameArray.length != 3) {
      return null;
    }

    if (
      pathNameArray[1] != AppRoutes.ResetPassword ||
      !searchParams.get("email") ||
      !pathNameArray[2].length
    ) {
      return null;
    }

    return {
      email: searchParams.get("email"),
      code: pathNameArray[2],
    };
  })();

  const handleEmailInputBlur = () => {
    validateEmail(email);
  };

  const handleInputFocus = () => {
    resetErrorMessage();
  };

  const handleInputDataChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const hasValidInputs = useMemo(() => {
    const validEmail = isEmailValid(email);
    const isValid = Boolean(email.length && password.length);
    return isValid && validEmail;
  }, [email, password]);

  const handleSubmit = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    event.preventDefault();
    signin(email, password);
  };

  const handleResetPasswordSubmit = () => {
    sendResetPasswordEmail(email);
  };

  const handleUpdatePasswordSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    resetPasswordSubmit(
      resetPasswordMetadata?.email as string,
      resetPasswordMetadata?.code as string,
      inputData.newPassword
    );
  };

  const handleNewPasswordChallengeSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    completeNewPassword(inputData.newPassword);
  };

  const isSignInButtonDisabled =
    !hasValidInputs || isLoading || !isEmailValid(email);

  const newPasswordFormProps = {
    network: { isLoading, errorMessage },
    onInputChange: handleInputDataChange,
    onInputFocus: handleInputFocus,
    input: inputData,
  };

  console.log((cognitoUser as any)?.challengeName, "Challenge name");

  // Check for MFA challenge directly on cognitoUser
  if ((cognitoUser as any)?.challengeName === MFA_APP_CHALLENGE_NAME) {
    return (
      <LoginCard>
        <MFAForm onSubmit={handleMFAVerification} />
      </LoginCard>
    );
  }

  // new user password challenge
  if (newPasswordRequired) {
    return (
      <NewPasswordForm
        {...newPasswordFormProps}
        hideBackToLogin
        onSubmit={handleNewPasswordChallengeSubmit}
        submitButtonText="Create Password"
      />
    );
  }

  if (resetPasswordMetadata) {
    return (
      <NewPasswordForm
        {...newPasswordFormProps}
        onBackToLogin={onBackToLoginClick}
        onSubmit={handleUpdatePasswordSubmit}
        submitButtonText="Update Password"
      />
    );
  }

  if (userForgotPassword) {
    return (
      <ResetPasswordForm
        network={{ isLoading, errorMessage }}
        onInputChange={handleInputDataChange}
        onInputFocus={handleInputFocus}
        email={email}
        onBackToLogin={onBackToLoginClick}
        onEmailInputBlur={handleEmailInputBlur}
        onResetPasswordSubmit={handleResetPasswordSubmit}
        invalidEmail={invalidEmail}
      />
    );
  }

  return (
    <LoginCard sx={{ pb: 6 }}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={6} mt={6} width="100%">
          <Typography variant="h6" textAlign="center">
            <strong>Sign in to your account</strong>
          </Typography>
          <Collapse in={showForgotPasswordConfirm}>
            <Typography>{AuthMessage.AfterForgotPasswordSubmit}</Typography>
          </Collapse>
          <TextField
            autoFocus
            onBlur={handleEmailInputBlur}
            autoComplete="email"
            name="email"
            onChange={handleInputDataChange}
            onFocus={handleInputFocus}
            value={email}
            placeholder="Email"
            type="text"
            fullWidth
            disabled={isLoading}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            name="password"
            type="password"
            onChange={handleInputDataChange}
            placeholder="Password"
            value={password}
            disabled={isLoading}
          />
        </Box>
        <Button sx={loginButtonStyles} onClick={onUserForgotPassword}>
          Forgot password?
        </Button>
        <Button
          type="submit"
          disabled={isSignInButtonDisabled}
          variant="contained"
          fullWidth
        >
          Sign In
        </Button>
        <Box display="flex" alignItems="center" my={3}>
          <Divider sx={{ flex: 1 }} />
          <Typography variant="body1" sx={{ mx: 2 }}>
            OR
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>
        <Button
          onClick={onOktaClick}
          fullWidth
          variant="outlined"
          aria-label="Sign in with Okta"
        >
          <img src={OktaLogoPNG} alt="Okta Logo" style={{ height: "22px" }} />
        </Button>
        <FormError
          show={Boolean(errorMessage || invalidEmail)}
          message={errorMessage}
          mt={2}
        />
      </form>
    </LoginCard>
  );
};

export default Login;
