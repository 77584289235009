import React from "react";
import { CognitoUser } from "@aws-amplify/auth";
import { OccuUser } from "./interfaces";

interface AuthContextInterface {
  cognitoUser: CognitoUser | null;
  completeNewPassword: (newPassword: string) => Promise<void>;
  errorMessage: string;
  isLoading: boolean;
  isAllZonesInactive: boolean;
  invalidEmail: boolean;
  occuUser: OccuUser | null;
  newPasswordRequired: boolean;
  mfaAppRequired: boolean;
  showForgotPasswordConfirm: boolean;
  refetchLoggedInUser: () => void;
  sendResetPasswordEmail: (email: string) => Promise<void>;
  resetPasswordSubmit: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  resetErrorMessage: () => void;
  signin: (username: string, password: string) => Promise<void>;
  signout: () => Promise<void>;
  validateEmail: (email: string) => void;
  userForgotPassword: boolean;
  handleMFAVerification: (code: string, rememberMe: boolean) => Promise<void>;
}

const AuthContext = React.createContext<AuthContextInterface>({
  cognitoUser: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  completeNewPassword: async () => {},
  errorMessage: "",
  isLoading: false,
  isAllZonesInactive: true,
  invalidEmail: false,
  occuUser: null,
  newPasswordRequired: false,
  mfaAppRequired: false,
  showForgotPasswordConfirm: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchLoggedInUser: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  sendResetPasswordEmail: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetErrorMessage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetPasswordSubmit: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signin: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signout: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  validateEmail: () => {},
  userForgotPassword: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleMFAVerification: async () => {},
});

export default AuthContext;
