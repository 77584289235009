import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AuthContext from "../../authContext";
import LoginCard from "./LoginCard";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";

interface Props {
  onSubmit: (inputData: InputData) => void;
}

export interface InputData {
  firstName: string;
  lastName: string;
  department: string;
  title: string;
}

const DEFAULT_INPUT_DATA = {
  firstName: "",
  lastName: "",
  department: "",
  title: "",
};

const FirstTimersForm: React.FC<Props> = ({ onSubmit }) => {
  const [inputData, setInputData] = useState<InputData>(DEFAULT_INPUT_DATA);
  const { firstName, lastName, department, title } = inputData;
  const authContext = useContext(AuthContext);

  const handleInputDataChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleSubmitClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ ...inputData });
  };

  const isSubmitDisabled =
    !firstName.trim().length ||
    !lastName.trim().length ||
    authContext.isLoading;

  return (
    <LoginCard isLarge>
      <form onSubmit={handleSubmitClick}>
        <Typography textAlign="center" my={8}>
          Please provide some additional information to get started
        </Typography>
        <Box
          mt={6}
          display="flex"
          flexDirection="column"
          rowGap={12}
          width="100%"
        >
          <Box display="flex" columnGap={6}>
            <TextField
              label="First Name"
              autoComplete="firstName"
              name="firstName"
              onChange={handleInputDataChange}
              value={firstName}
              placeholder="First Name"
              type="text"
              fullWidth
              disabled={authContext.isLoading}
            />
            <TextField
              label="Last Name"
              autoComplete="lastName"
              name="lastName"
              onChange={handleInputDataChange}
              value={lastName}
              placeholder="Last Name"
              type="text"
              fullWidth
              disabled={authContext.isLoading}
            />
          </Box>
          <Box display="flex" columnGap={6} mb={8}>
            <TextField
              label="Title (optional)"
              autoComplete="title"
              name="title"
              onChange={handleInputDataChange}
              value={title}
              placeholder="Title"
              type="text"
              fullWidth
              disabled={authContext.isLoading}
            />
            <TextField
              label="Department (optional)"
              autoComplete="department"
              name="department"
              onChange={handleInputDataChange}
              value={department}
              placeholder="Department"
              type="text"
              fullWidth
              disabled={authContext.isLoading}
            />
          </Box>
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitDisabled}
        >
          Activate Account
        </Button>
        <FormError
          show={Boolean(authContext.errorMessage)}
          message={AuthMessage.FirstTimeFormFail}
        />
      </form>
    </LoginCard>
  );
};

export default FirstTimersForm;
