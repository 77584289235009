import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import Container from "@mui/material/Container";
import Login from "./views/Login";
import FirstTimersForm, {
  InputData as FirstTimerFormInputData,
} from "./views/Login/FirstTimersForm";
import MFAForm from "./views/Login/MFAForm";
import AuthContext from "./authContext";
import TrackerContext from "./TrackerContext";
import { AppSyncError, AuthenticationType, OccuUser } from "./interfaces";
import { AppRoutes } from "./routing";
import { isEmailValid } from "./util";
import { getCurrentFirstPath } from "./routing";
import LoadingCircle from "./components/LoadingCircle";
import { setUserId } from "@snowplow/browser-tracker";
import { AuthMessage } from "./util/copy";
import { ApolloError } from "./network";
import SetupMFA from "./views/Login/SetupMFA";

interface Props {
  children: React.ReactNode;
}

export interface AuthProviderState {
  cognitoUser: CognitoUser | null;
  isLoading: boolean;
  isAllZonesInactive: boolean;
  isInitialLoad: boolean;
  errorMessage: string;
  occuUser: OccuUser | null;
  newPasswordRequired: boolean;
  mfaAppRequired: boolean;
  userForgotPassword: boolean;
  showForgotPasswordConfirm: boolean;
  invalidEmail: boolean;
  mfaSetupRequired: boolean;
}

// Regex for at least 8 characters, one uppercase and one digit
export const eightCharRegex = new RegExp("^(?=.{8,}$)");
export const oneUppercaseRegex = new RegExp("^(?=.*?[A-Z])");
export const oneDigitRegex = new RegExp("^(?=.*?[0-9])");

const GetMe = gql`
  query GetMe {
    GetMe {
      id
      fullName
      department
      title
      firstName
      lastName
      email
      role
      lastActive
      customerName
      customerId
      cognitoCreateDate
      authentication
      customerMFA
    }
  }
`;

const RequiredNewUserMutation = gql`
  mutation RequiredNewUserMutation(
    $userId: Int!
    $firstNameInput: String!
    $lastNameInput: String!
    $departmentInput: String!
    $titleInput: String!
  ) {
    EditUserName(
      userId: $userId
      firstNameInput: $firstNameInput
      lastNameInput: $lastNameInput
    )
    EditUserDepartment(userId: $userId, departmentInput: $departmentInput)
    EditUserTitle(userId: $userId, titleInput: $titleInput)
  }
`;

const NEW_PASSWORD_CHALLENGE_NAME = "NEW_PASSWORD_REQUIRED";
export const MFA_APP_CHALLENGE_NAME = "SOFTWARE_TOKEN_MFA";

const DEFAULT_AUTH_PROVIDER_STATE = {
  cognitoUser: null,
  occuUser: null,
  isLoading: true,
  isAllZonesInactive: true,
  isInitialLoad: true,
  invalidEmail: false,
  errorMessage: "",
  newPasswordRequired: false,
  mfaAppRequired: false,
  showForgotPasswordConfirm: false,
  userForgotPassword: false,
  mfaSetupRequired: false,
};

const authContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  p: { xs: 0 },
};

const loginRoutes = new Set([
  AppRoutes.Login,
  AppRoutes.ResetPassword,
  AppRoutes.Register,
]);

const isLocal = process.env.NODE_ENV === "local";
const isStaging = process.env.IS_STAGING === "true";
const isProduction = process.env.NODE_ENV === "production";
const redirectURL = isLocal
  ? "http://localhost:8080"
  : isStaging
  ? "https://staging.portal.occuspace.io"
  : isProduction
  ? "https://portal.occuspace.io"
  : "https://dev.portal.occuspace.io";

export const authConfig = {
  authenticationFlowType: "USER_PASSWORD_AUTH",
  region: "us-west-2",
  userPoolId: process.env.USER_POOL_ID,
  authority: `https://cognito-idp.us-west-2.amazonaws.com/${process.env.USER_POOL_ID}`,
  oauth: {
    domain: process.env.OAUTH_DOMAIN,
    scope: ["openid", "email", "profile"],
    redirectSignIn: redirectURL,
    redirectSignOut: redirectURL,
    responseType: "code",
    options: {
      identityProvider: "Okta",
      pkce: true,
    },
  },
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { tracker } = useContext(TrackerContext);
  const [state, setState] = useState<AuthProviderState>(
    DEFAULT_AUTH_PROVIDER_STATE
  );
  const [getOccuUser] = useLazyQuery(GetMe, {
    fetchPolicy: "network-only",
  });
  const [mutateNewUser] = useMutation(RequiredNewUserMutation);

  const firstPath = getCurrentFirstPath(pathname);

  // when the page loads, we first see if `Auth` finds an already logged in user
  useEffect(() => {
    // if the user is trying to reset their password, do not try to get a user
    if (firstPath === AppRoutes.Register) {
      return;
    }

    if (firstPath === AppRoutes.ResetPassword) {
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
      });
      return;
    }

    (async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (cognitoUser) {
          const clientId = cognitoUser.pool.clientId;
          const isOktaUser = clientId === process.env.OKTA_USER_POOL_CLIENT_ID;
          if (isOktaUser) {
            Auth.configure({
              ...authConfig,
              userPoolWebClientId: process.env.OKTA_USER_POOL_CLIENT_ID,
            });
          } else {
            Auth.configure({
              ...authConfig,
              userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
            });
          }
          await handleLoggedInCognitoUser(cognitoUser);
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (Auth as any)._storage.clear();
        // this happens when Auth can't find an already logged in user
        setState({
          ...state,
          isLoading: false,
          isInitialLoad: false,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (firstPath === AppRoutes.Register) {
      const user = searchParams.get("user");
      const code = searchParams.get("code");

      signin(user || "", code || "");
      return;
    }
  }, [firstPath]);

  useEffect(() => {
    if (
      loginRoutes.has(firstPath as AppRoutes) &&
      cognitoUser != null &&
      state.occuUser &&
      state.occuUser.firstName &&
      state.occuUser.lastName
    ) {
      navigate("/", { replace: true });
    }
  }, [firstPath, state.cognitoUser, state.occuUser]);

  useEffect(() => {
    if (occuUser?.id && tracker) {
      setUserId(occuUser.id);
    }
  }, [state.occuUser, tracker]);

  const handleOktaClick = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    Auth.configure({
      ...authConfig,
      userPoolWebClientId: process.env.OKTA_USER_POOL_CLIENT_ID,
    });

    try {
      await Auth.federatedSignIn({
        customProvider: "Okta",
      });
      setState({
        ...state,
        isLoading: false,
      });
    } catch (error) {
      setState({
        ...state,
        errorMessage: AuthMessage.OktaError,
        isLoading: false,
      });
    }
  };

  const refetchLoggedInUser = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const {
        data: { GetMe: user },
      } = await getOccuUser();
      setState({
        ...state,
        isLoading: false,
        occuUser: { ...user },
      });
    } catch {
      setState({
        ...state,
        isLoading: false,
        errorMessage: AuthMessage.DidNotGetUser,
      });
    }
  };

  const handleLoggedInCognitoUser = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cognitoUser: any
  ) => {
    console.log("handleLoggedInCognitoUser called with:", cognitoUser);

    if (cognitoUser.challengeName === NEW_PASSWORD_CHALLENGE_NAME) {
      console.log("New password challenge detected");
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
        errorMessage: "",
        newPasswordRequired: true,
        cognitoUser,
      });
      return;
    }

    if (cognitoUser.challengeName === MFA_APP_CHALLENGE_NAME) {
      console.log("MFA challenge still present after verification");
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
        errorMessage: "",
        mfaAppRequired: true,
        cognitoUser,
      });
      return;
    }

    try {
      console.log("No challenges, proceeding with user fetch");
      if (state.isLoading !== true) {
        setState({
          ...state,
          isLoading: true,
        });
      }
      const {
        data: { GetMe: user },
      } = await getOccuUser();
      console.log("Got user data:", user);

      // Check if MFA setup is required
      if (user.customerMFA) {
        try {
          const preferredMFA = await Auth.getPreferredMFA(cognitoUser);
          if (preferredMFA === "NOMFA") {
            setState({
              ...state,
              errorMessage: "",
              isLoading: false,
              isInitialLoad: false,
              occuUser: { ...user },
              cognitoUser,
              mfaSetupRequired: true,
            });
            return;
          }
        } catch (mfaError) {
          console.error("Error checking MFA preference:", mfaError);
          setState({
            ...state,
            errorMessage: "",
            isLoading: false,
            isInitialLoad: false,
            occuUser: { ...user },
            cognitoUser,
            mfaSetupRequired: true,
          });
          return;
        }
      }

      setState({
        ...state,
        errorMessage: "",
        isLoading: false,
        isInitialLoad: false,
        occuUser: { ...user },
        cognitoUser,
      });
    } catch (e) {
      console.error("Error in handleLoggedInCognitoUser:", e);
      setState({
        ...state,
        cognitoUser,
        isLoading: false,
        isInitialLoad: false,
        errorMessage: AuthMessage.DidNotGetUser,
      });
    }
  };

  const handleFirstTimerFormSubmit = async ({
    firstName,
    lastName,
    department,
    title,
  }: FirstTimerFormInputData) => {
    try {
      setState({
        ...state,
        isLoading: true,
      });
      await mutateNewUser({
        variables: {
          firstNameInput: firstName.trim(),
          lastNameInput: lastName.trim(),
          departmentInput: department.trim(),
          titleInput: title.trim(),
          userId: (state.occuUser as OccuUser).id,
        },
      });
      const {
        data: { GetMe: user },
      } = await getOccuUser();
      setState({
        ...state,
        isLoading: false,
        occuUser: { ...user },
      });
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: AuthMessage.FirstTimeFormFail,
      });
    }
  };

  const sendResetPasswordEmail = async (email: string) => {
    try {
      setState({
        ...state,
        isLoading: true,
        errorMessage: "",
      });
      await Auth.forgotPassword(email.toLocaleLowerCase());
      setState({
        ...state,
        isLoading: false,
        userForgotPassword: false,
        showForgotPasswordConfirm: true,
      });
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        userForgotPassword: false,
        showForgotPasswordConfirm: true,
      });
    }
  };

  const resetErrorMessage = () => {
    setState({
      ...state,
      errorMessage: "",
      invalidEmail: false,
    });
  };

  // submits a new user password via reset password flow
  const resetPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string
  ) => {
    try {
      setState({
        ...state,
        isLoading: true,
        errorMessage: "",
      });
      await Auth.forgotPasswordSubmit(
        username.toLocaleLowerCase(),
        code,
        newPassword
      );
      // This was added during testing of cognito user pool migration
      // Without it, after a migrating user reset their password they were unable to login
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (Auth as any)._storage.clear();
      // Sign in users resetting password
      // First time Users get sent to FirstTimers form
      if (!state.newPasswordRequired) {
        signin(username.toLocaleLowerCase(), newPassword);
      } else {
        setState({
          ...state,
          isLoading: false,
          errorMessage: "",
        });
      }
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: AuthMessage.Unexpected,
      });
    }
  };

  const signin = async (username: string, password: string) => {
    Auth.configure({
      ...authConfig,
      userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
    });
    const lowercaseUsername = username.toLocaleLowerCase();
    try {
      setState({
        ...state,
        isLoading: true,
      });
      const cognitoUser = await Auth.signIn(lowercaseUsername, password);

      await handleLoggedInCognitoUser(cognitoUser);
    } catch (e) {
      let error = AuthMessage.WrongCreds;
      if ((e as ApolloError).message === AppSyncError.UserDoesNotExist) {
        error = AuthMessage.UserDoesNotExist;
      }
      setState({
        ...state,
        isLoading: false,
        isInitialLoad: false,
        errorMessage: error,
      });
    }
  };

  const signout = async () => {
    navigate("/", { replace: true });
    try {
      setState({
        ...state,
        isLoading: true,
      });
      if (occuUser?.authentication === AuthenticationType.OKTA) {
        Auth.configure({
          ...authConfig,
          userPoolWebClientId: process.env.OKTA_USER_POOL_CLIENT_ID,
        });
      } else {
        Auth.configure({
          ...authConfig,
          userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
        });
      }

      // Use signOut with global: false to preserve device memory
      // This will keep the device remembered while signing out
      await Auth.signOut({ global: false });

      setState({
        ...state,
        newPasswordRequired: false,
        userForgotPassword: false,
        isLoading: false,
        occuUser: null,
        cognitoUser: null,
      });
      return;
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: AuthMessage.FailedToSignOut,
      });
      return;
    }
  };

  const completeNewPassword = async (newPassword: string) => {
    try {
      setState({
        ...state,
        isLoading: true,
      });

      const cognitoUser = await Auth.completeNewPassword(
        state.cognitoUser,
        newPassword
      );
      await handleLoggedInCognitoUser(cognitoUser);
    } catch (e) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: AuthMessage.SubmitNewPasswordFailed,
      });
    }
  };

  const validateEmail = (email: string) => {
    if (!email) {
      return;
    }
    const validEmail = isEmailValid(email);
    if (!validEmail) {
      setState({
        ...state,
        invalidEmail: true,
      });
    }
  };

  const handleUserForgotPassword = () => {
    setState({
      ...state,
      userForgotPassword: true,
      errorMessage: "",
    });
  };

  const handleBackToLoginClick = () => {
    setState({
      ...state,
      userForgotPassword: false,
      errorMessage: "",
    });
  };

  const handleMFAVerification = async (code: string, rememberMe: boolean) => {
    try {
      console.log("Starting MFA verification with code:", code);
      setState({
        ...state,
        isLoading: true,
        errorMessage: "",
      });

      if (!state.cognitoUser) {
        console.error("No cognito user found");
        throw new Error("No cognito user found");
      }

      console.log("Current cognito user:", state.cognitoUser);

      // Use confirmSignIn for MFA verification with the correct parameters
      let cognitoUser: CognitoUser;
      try {
        console.log("trying to confirm sign in");
        cognitoUser = await Auth.confirmSignIn(
          state.cognitoUser,
          code,
          MFA_APP_CHALLENGE_NAME,
          { rememberDevice: rememberMe ? "true" : "false" }
        );

        if (rememberMe) {
          try {
            // This will remember the device in Cognito
            await Auth.rememberDevice();
            console.log("Device remembered successfully");
            // Set preferred MFA to SOFTWARE_TOKEN_MFA
            await Auth.setPreferredMFA(cognitoUser, "SOFTWARE_TOKEN_MFA");
            console.log("Preferred MFA set to SOFTWARE_TOKEN_MFA");
          } catch (error) {
            console.error("Error remembering device:", error);
          }
        }

        console.log({ cognitoUser }, "cognitoUser from confirmSignIn");
      } catch (e) {
        console.error("MFA verification error:", e);
      }

      // Get fresh user and session
      const freshUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      await Auth.currentSession();

      // Update the cognito user state and clear MFA requirement
      setState((prevState) => ({
        ...prevState,
        cognitoUser: cognitoUser,
        mfaAppRequired: false,
        isLoading: false,
      }));

      // After successful MFA verification, get the current authenticated user
      const currentUser = await Auth.currentAuthenticatedUser();
      await handleLoggedInCognitoUser(currentUser);
    } catch (e) {
      console.error("MFA verification error:", e);
      // Log more details about the error
      if (e instanceof Error) {
        console.error("Error name:", e.name);
        console.error("Error message:", e.message);
        console.error("Error stack:", e.stack);

        // If we get a 400 error, check if the challenge is gone
        if (e.message.includes("400")) {
          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            if (!(currentUser as any)?.challengeName) {
              console.log("MFA verification succeeded despite 400 error");
              setState((prevState) => ({
                ...prevState,
                mfaAppRequired: false,
                isLoading: false,
              }));
              await handleLoggedInCognitoUser(currentUser);
              return; // Exit early since we handled the success case
            }
          } catch (sessionError) {
            console.error("Error checking current user:", sessionError);
          }
        }

        // Handle specific error types
        let errorMessage: AuthMessage = AuthMessage.MFAVerificationFailed;
        if (e.message.includes("CodeMismatchException")) {
          errorMessage = AuthMessage.WrongCreds;
        } else if (e.message.includes("Invalid device key")) {
          errorMessage = AuthMessage.Unexpected;
        }

        setState({
          ...state,
          isLoading: false,
          errorMessage,
        });
      }
    }
  };

  const handleMFASetupComplete = () => {
    setState((prevState) => ({
      ...prevState,
      mfaSetupRequired: false,
    }));
  };

  const {
    cognitoUser,
    errorMessage,
    invalidEmail,
    isLoading,
    isAllZonesInactive,
    newPasswordRequired,
    mfaAppRequired,
    occuUser,
    userForgotPassword,
    showForgotPasswordConfirm,
    mfaSetupRequired,
  } = state;

  const authContextValue = {
    cognitoUser,
    completeNewPassword,
    errorMessage,
    invalidEmail,
    isLoading,
    isAllZonesInactive,
    newPasswordRequired,
    mfaAppRequired,
    showForgotPasswordConfirm,
    occuUser,
    refetchLoggedInUser,
    resetErrorMessage,
    resetPasswordSubmit,
    sendResetPasswordEmail,
    signin,
    signout,
    validateEmail,
    userForgotPassword,
    handleMFAVerification,
  };

  console.log("Auth provider");

  if (
    state.isInitialLoad ||
    (firstPath === AppRoutes.Register &&
      !errorMessage &&
      !isLoading &&
      !cognitoUser)
  ) {
    console.log("Loading circle");
    return (
      <AuthContext.Provider value={authContextValue}>
        <LoadingCircle />
      </AuthContext.Provider>
    );
  }

  if (mfaSetupRequired) {
    console.log("MFA setup required");
    return (
      <AuthContext.Provider value={authContextValue}>
        <Container sx={authContainerStyles} maxWidth="sm">
          <SetupMFA onSetupComplete={handleMFASetupComplete} />
        </Container>
      </AuthContext.Provider>
    );
  }

  if (occuUser && (!occuUser.firstName || !occuUser.lastName)) {
    console.log("First timers");
    return (
      <AuthContext.Provider value={authContextValue}>
        <Container sx={authContainerStyles} maxWidth="sm">
          <FirstTimersForm onSubmit={handleFirstTimerFormSubmit} />
        </Container>
      </AuthContext.Provider>
    );
  }

  console.log({ cognitoUser });
  console.log((cognitoUser as any)?.challengeName);

  // Check for MFA challenge
  if (
    cognitoUser &&
    (cognitoUser as any).challengeName === MFA_APP_CHALLENGE_NAME
  ) {
    console.log("MFA challenge detected");
    return (
      <AuthContext.Provider value={authContextValue}>
        <Container sx={authContainerStyles} maxWidth="sm">
          <Login
            onUserForgotPassword={handleUserForgotPassword}
            onBackToLoginClick={handleBackToLoginClick}
            onOktaClick={handleOktaClick}
          />
        </Container>
      </AuthContext.Provider>
    );
  }

  if (cognitoUser == null || occuUser === null) {
    console.log("Login");

    return (
      <AuthContext.Provider value={authContextValue}>
        <Container sx={authContainerStyles} maxWidth="sm">
          <Login
            onUserForgotPassword={handleUserForgotPassword}
            onBackToLoginClick={handleBackToLoginClick}
            onOktaClick={handleOktaClick}
          />
        </Container>
      </AuthContext.Provider>
    );
  }

  console.log("Auth checks passed");

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
