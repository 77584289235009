import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";
import AuthContext from "../../authContext";

interface Props {
  onSubmit: (code: string, rememberMe: boolean) => Promise<void>;
}

const MFAForm: React.FC<Props> = ({ onSubmit }) => {
  const [code, setCode] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { isLoading, errorMessage } = useContext(AuthContext);

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(code, rememberMe);
      }}
      mt={4}
      sx={{ width: "100%" }}
    >
      <Typography variant="h6" textAlign="center" mb={4}>
        <strong>Enter verification code</strong>
      </Typography>
      <TextField
        autoFocus
        fullWidth
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="Enter code"
        type="text"
        disabled={isLoading}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            disabled={isLoading}
          />
        }
        label="Remember me for 7 days"
        sx={{
          pt: 2,
        }}
      />
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={isLoading || !code}
        sx={{ mt: 3 }}
      >
        {isLoading ? "Verifying..." : "Verify"}
      </Button>
      <FormError show={Boolean(errorMessage)} message={errorMessage} mt={2} />
    </Box>
  );
};

export default MFAForm;
