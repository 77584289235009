import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoginCard from "./LoginCard";
import PasswordStrengthStatus from "../../components/PasswordStrengthStatus";
import { loginButtonStyles } from "./util";
import FormError from "../../components/FormError";
import {
  eightCharRegex,
  oneUppercaseRegex,
  oneDigitRegex,
} from "../../AuthProvider";
import { AuthMessage } from "../../util/copy";

interface Props {
  network: { isLoading: boolean; errorMessage: string };
  onInputChange: ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onBackToLogin?: () => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInputFocus: () => void;
  hideBackToLogin?: boolean;
  submitButtonText: string;
  input: { newPassword: string; confirmNewPassword: string };
}

const NewPasswordForm: React.FC<Props> = ({
  onInputChange,
  onBackToLogin,
  onSubmit,
  onInputFocus,
  hideBackToLogin,
  submitButtonText,
  network: { isLoading, errorMessage },
  input: { newPassword, confirmNewPassword },
}) => {
  const navigate = useNavigate();
  const charError = Boolean(!eightCharRegex.test(newPassword));
  const uppercaseError = Boolean(!oneUppercaseRegex.test(newPassword));
  const digitError = Boolean(!oneDigitRegex.test(newPassword));

  const passwordsDoNotMatchError =
    confirmNewPassword.length > 1 && newPassword !== confirmNewPassword;

  const isSubmitDisabled =
    isLoading ||
    !newPassword ||
    !confirmNewPassword ||
    passwordsDoNotMatchError ||
    charError ||
    uppercaseError ||
    digitError;

  const handleBackToLoginClick = () => {
    navigate("/", { replace: true });
    onBackToLogin && onBackToLogin();
  };

  return (
    <LoginCard>
      <form style={{ width: "100%" }} onSubmit={onSubmit}>
        <Typography textAlign="center" mt={8} mb={4}>
          Set the password for your Occuspace account
        </Typography>
        <Box display="flex" flexDirection="column" rowGap={2} width="100%">
          <TextField
            sx={{
              // Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq)
              display: "none",
            }}
            autoComplete="email"
            name="email"
            placeholder="Email"
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            autoComplete="new-password"
            name="newPassword"
            onChange={onInputChange}
            onFocus={onInputFocus}
            value={newPassword}
            placeholder="New Password"
            type="password"
            fullWidth
            disabled={isLoading}
          />
          <PasswordStrengthStatus
            text="Must be at least 8 characters"
            error={charError}
          />
          <PasswordStrengthStatus
            text="Must have at least one uppercase letter"
            error={uppercaseError}
          />
          <PasswordStrengthStatus
            text="Must have at least one number"
            error={digitError}
          />
          <TextField
            sx={{ mt: 2 }}
            autoComplete="confirm-new-password"
            name="confirmNewPassword"
            onChange={onInputChange}
            onFocus={onInputFocus}
            value={confirmNewPassword}
            placeholder="Confirm Password"
            type="password"
            fullWidth
            disabled={isLoading}
          />
          {passwordsDoNotMatchError && (
            <PasswordStrengthStatus
              text={AuthMessage.PasswordsDoNotMatch}
              error={passwordsDoNotMatchError}
            />
          )}
        </Box>
        {!hideBackToLogin && onBackToLogin && (
          <Button
            sx={loginButtonStyles}
            onClick={handleBackToLoginClick}
            disabled={isLoading}
          >
            Back to Login
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitDisabled}
          sx={{ mt: 8 }}
        >
          {submitButtonText}
        </Button>
        <FormError
          message={AuthMessage.SubmitNewPasswordFailed}
          show={Boolean(errorMessage)}
        />
      </form>
    </LoginCard>
  );
};

export default NewPasswordForm;
